@import 'variables';

.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: calc(80px + constant(safe-area-inset-top));
  height: calc(80px + env(safe-area-inset-top));
  background: var(--base-color);
  z-index: 10;
  pointer-events: auto;

  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);

  @media #{ $pcview } {
    height: calc(105px + constant(safe-area-inset-top));
    height: calc(105px + env(safe-area-inset-top));
  }

  /* ========================================= //
  // モーダル表示用
  // ========================================= */
  &.__modalStyle__ {
    position: fixed;
    top: 56px;
    background: var(--base-color-modal);

    &.noHeightTranslucent {
      background: rgba(var(--base-color-rgb-modal), 0.7);
    }
  }

  /* ========================================= //
  // ヘッダー帯なし
  // ========================================= */
  &.noHeightTransparent {
    background: none;
  }

  /* ========================================= //
  // ヘッダー帯あり (MV上)
  // ========================================= */
  &.noHeightTranslucent {
    background: rgba(var(--base-color-rgb), 0.7);

    &~.hero {
      margin-top: -100px;

      @media #{ $pcview } {
        margin-top: -120px;
      }
    }
  }

  &_container {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 35px);
    // margin: 0 15px 0 20px;
    margin: auto;
    padding: 14px 0px;
    max-width: 1024px;
    height: 100%;

    @media #{ $pcview } {
      width: calc(100% - 10px);
      padding: 0 15px 0 10px;
    }
  }

  &_logo_group_land {
    transition: all 0.3s;
    max-width: 140px;
    margin: auto 0;
    line-height: 0;
    cursor: pointer;
  }

  &_logo_land {
    transition: all 0.3s ease;

    &_img_land {
      max-height: 52px;

      @media #{ $pcview } {
        max-height: 70px;
        max-width: 140px;
      }
    }

    &.noClick {
      pointer-events: none;
    }
  }

  /* ========================================= //
  // ロゴ中心の場合
  // ========================================= */
  &.centerLogo {
    .header_container {
      justify-content: flex-end;
    }

    .header_logo {
      position: absolute;
      right: 0;
      left: 0;
      margin: 0 auto;
      display: inline-block;
      width: 150px;
      text-align: center;
      letter-spacing: 0.06em;

      @media #{ $pcview } {
        position: relative;
        display: block;
        text-align: left;
        // width: auto;
        margin-left: 0;
      }
    }
  }

  /* ========================================= //
  // ヘッダー内ロゴ無しの場合
  // ========================================= */
  &.noLogo {
    .header_logo {
      display: none;

      @media #{ $pcview } {
        display: block;
      }
    }

    .header_container {
      justify-content: flex-end;
    }
  }

  &_openBg {
    display: none;
    /*はじめは隠しておく*/
    position: fixed;
    z-index: 99;
    top: 0;
    /*全体に広がるように*/
    left: 0;
    width: 100%;
    height: 100%;
    // background: functional_color;
    opacity: 0;
    transition: 0.3s ease-in-out;
  }

  /*チェックボックス等は非表示に*/
  &_checkbox {
    display: none;

    &:checked~.header_menuIcon {
      display: none;
    }

    &:checked~.header_openBg {
      display: block;
      /*カバーを表示*/
      opacity: 0.5;
    }

    &:checked~.header_content {
      // -webkit-transform: translateX(0%);
      // transform: translateX(0%);/*中身を表示（右へスライド）*/
      box-shadow: 6px 0 25px rgba(0, 0, 0, 0.15);
    }

    &:checked~.header_content {

      /* モバイルメニューオープン時の閉じるボタン */
      .header_content__closeBtn {
        display: inline-block;
        position: absolute;
        top: calc(18px + constant(safe-area-inset-top));
        top: calc(18px + env(safe-area-inset-top));
        // right: 2.5%;
        right: 16px;
        width: 30px;
        height: 22px;
        vertical-align: middle;
        cursor: pointer;

        span {
          position: absolute;
          height: 2px;
          /*線の太さ*/
          width: 20px;
          /*長さ*/
          background: transparent;
          display: block;
          content: '';

          &::before,
          &::after {
            position: absolute;
            height: 2px;
            /*線の太さ*/
            width: 20px;
            /*長さ*/
            border-radius: 3px;
            background: var(--functional-color);
            display: block;
            content: '';
          }

          &::before {
            bottom: -15px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
          }

          &::after {
            bottom: -15px;
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
          }
        }
      }
    }
  }

  &_menuIcon {
    display: block;
    width: 30px;
    height: 18px;
    margin: auto 0 auto 0;
    transition: all 0.3s;
    // margin: auto 0 auto auto;
    cursor: pointer;

    // margin-left: auto;
    // @media ($ipadv) {
    @media #{ $pcview } {
      display: none;
    }

    &.__modalStyle__ {
      span {
        background: var(--functional-color-modal);

        &::before,
        &::after {
          background: var(--functional-color-modal);
        }
      }
    }

    span {
      display: block;
      position: absolute;
      height: 2px;
      /*線の太さ*/
      width: 29px;
      /*長さ*/
      background: var(--functional-color);
      cursor: pointer;

      &::before,
      &::after {
        position: absolute;
        height: 2px;
        /*線の太さ*/
        width: 29px;
        /*長さ*/
        background: var(--functional-color);
        display: block;
        content: '';
      }

      &::before {
        bottom: -8px;
      }

      &::after {
        bottom: -16px;
      }
    }
  }

  &_myPageIcon {
    display: block;
    width: 28px;
    height: 28px;
    margin: auto 20px auto auto;
    cursor: pointer;

    @media #{ $pcview } {
      display: none;
    }

    &.__modalStyle__ {
      .header_myPageIcon_svg {
        fill: var(--functional-color-modal);
      }

      .header_myPageIcon_svg_active {
        fill: var(--functional-color-modal);
      }
    }

    .header_myPageIcon_svg {
      opacity: 1;
      fill: var(--functional-color);
    }

    .header_myPageIcon_svg_active {
      opacity: 0;
      fill: var(--functional-color);
    }

    &:active {
      .header_myPageIcon_svg {
        opacity: 0;
      }

      .header_myPageIcon_svg_active {
        opacity: 1;
      }
    }
  }

  /*中身*/
  &_content {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    /* 最前面に */
    width: 100%;
    /* 右側に隙間を作る場合は100%未満 */
    max-width: 100%;
    /* 最大幅を決める際は絶対値指定など */
    height: 100%;
    background: rgba(var(--base-color-rgb), 0.85);
    /*背景色*/
    // -webkit-backdrop-filter: blur(10px) brightness(1.15);

    // transition: .3s ease-in-out; /* 滑らかに表示 */
    // -webkit-transform: translateX(-105%);
    // transform: translateX(-105%);/*左に隠しておく*/
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    transition: all 0.3s;

    @media #{ $pcview } {
      position: relative;
      height: auto;
      background: none;
      -webkit-transform: none;
      transform: none;
      display: flex;
      overflow: auto;
      margin: auto 0;
    }

    &.__modalStyle__ {
      margin-top: auto;
      margin-bottom: auto;
      height: calc(100% - 56px);
      background: rgba(var(--base-color-rgb-modal), 0.85);
      @media #{ $pcview } {
        top: 0;
      }
    }

    /* ヘッダーメニューナビゲーション */
    &_nav {
      display: flex;
      align-items: baseline;
      margin: auto 0 auto auto;
      width: 100%;
      overflow-y: scroll;
      height: 100%;

      @media #{ $pcview } {
        overflow-y: hidden;
        height: 40px;
      }

      &__list {
        width: 100%;
        margin: 60px 0;
        padding: 0 50px;

        @media #{ $pcview } {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          align-items: center;
          margin: 0;
          padding: 0;
          height: 30px;
          overflow-y: hidden;
        }

        &.noMargin {
          margin-top: 0px;
        }
      }

      &__item {
        text-align: center;
        letter-spacing: 0.06em;
        max-width: 90%;
        margin: 0 auto 40px;

        @media #{ $pcview } {
          margin: 0;
          padding-left: 28px;
          display: block;
          align-items: center;
          height: 30px;
          max-width: unset;
        }

        &__logo {
          margin-bottom: 62px;
          @media #{ $pcview } {
            display: none;
          }
          &.__modalStyle__ {
            a {
              color: var(--functional-color-modal);
            }
          }
          a {
            font-size: 26px;
            color: var(--functional-color);
            text-decoration: none;
          }
        }

        &__otherBtn {
          display: none;
          @media #{ $pcview } {
            display: block;
            padding-top: 10px;
          }
        }

        &__shopListBtn {
          cursor: pointer;
          display: none;
          @media #{ $pcview } {
            display: block;
          }
          &__icon {
            width: 20px;
            height: 30px;
          }
        }

        &__bookBtn {
          cursor: pointer;
          display: none;
          @media #{ $pcview } {
            display: block;
            max-width: unset;
          }
        }

        &.hide {
          display: none;
        }

        &.shop-list-map-padding {
          padding-left: 14px;
        }
      }

      &__link {
        color: var(--functional-color);
        text-decoration: none;
        font-size: 14px;
        transition: all 0.4s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        word-break: break-all;

        @media #{ $pcview } {
          word-break: keep-all;
          height: 18px;
        }

        &.__modalStyle__ {
          color: var(--functional-color-modal);
        }

        &:hover {
          opacity: 0.4;
          transition: all 0.4s ease;
        }

        &:focus {
          outline: none;
        }

        &__childMenuIcon {
          position: relative;
          top: 0;
          flex: 0 0 18px;
          text-decoration: none;
          font-size: 14px;
          width: 18px;
          height: 18px;
          background-color: var(--functional-color);
          mask-image: url('/assets/images/icons/chevron-down.svg');
          -webkit-mask-image: url('/assets/images/icons/chevron-down.svg');
          mask-repeat: no-repeat;
          -webkit-mask-repeat: no-repeat;
          mask-size: cover;
          -webkit-mask-size: cover;
          margin-left: 3px;

          &.__modalStyle__ {
            background-color: var(--functional-color-modal);
          }

          &.open {
            transform: rotate(180deg);
          }
        }

        // マイページメニューで使用
        &.nohover {
          &:hover {
            opacity: 1;
            transition: none;
          }

          &:active {
            opacity: 0.4;
          }
        }
      }

      &__childList {
        display: none;
        position: absolute;
        z-index: 9999;
        transform: translateZ(1px);
        background-color: var(--functional-color);
        min-width: 100px;
        max-width: 350px;
        padding: 10px;
        border-radius: 4px;
        box-shadow: 3px 3px 10px rgba(#000, 0.4);
        margin-top: 10px;
        @media #{ $pcview } {
          display: block;
          position: fixed;
        }

        &.__modalStyle__ {
          background-color: var(--functional-color-modal);

          &::before {
            border-bottom-color: var(--functional-color-modal);
          }
        }

        &::before {
          content: '';
          position: absolute;
          top: -14px;
          left: 23px;
          border: 7px solid transparent;
          border-bottom-color: var(--functional-color);
        }
      }

      &__childItem {
        margin-bottom: 40px;
        text-align: left;
        letter-spacing: 0.06em;

        @media #{ $pcview } {
          margin-bottom: 0;
          position: relative;
          z-index: 3;
        }

        &:not(:last-child) {
          @media #{ $pcview } {
            padding-bottom: 15px;
          }
        }
      }

      &__childLink {
        color: var(--base-color);
        text-decoration: none;
        font-size: 14px;
        transition: all 0.4s ease;
        overflow-wrap: anywhere;
        word-break: keep-all;
        cursor: pointer;

        &.__modalStyle__ {
          color: var(--base-color-modal);
        }

        &:hover {
          opacity: 0.4;
          transition: all 0.4s ease;
        }

        &:focus {
          outline: none;
        }
      }

      &__otherList {
        display: block;
        position: fixed;
        margin-left: -20px;
        margin-top: 23px;
        z-index: 9999;
        transform: translateZ(1px);
        background-color: var(--functional-color);
        min-width: 100px;
        max-width: 350px;
        padding: 10px;
        border-radius: 4px;
        box-shadow: 3px 3px 10px rgba(#000, 0.4);

        &.__modalStyle__ {
          background-color: var(--functional-color-modal);

          &::before {
            border-bottom-color: var(--functional-color-modal);
          }
        }

        &::before {
          content: '';
          position: absolute;
          top: -14px;
          left: 23px;
          border: 7px solid transparent;
          border-bottom-color: var(--functional-color);
        }
      }

      &__otherItem {
        margin-bottom: 40px;
        text-align: left;
        letter-spacing: 0.06em;

        @media #{ $pcview } {
          margin-bottom: 0;
          position: relative;
          z-index: 3;
        }

        &:not(:last-child) {
          @media #{ $pcview } {
            padding-bottom: 15px;
          }
        }
      }

      &__otherLink {
        color: var(--base-color);
        text-decoration: none;
        font-size: 14px;
        transition: all 0.4s ease;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow-wrap: anywhere;
        word-break: keep-all;

        &.__modalStyle__ {
          color: var(--base-color-modal);
        }

        &:hover {
          opacity: 0.4;
          transition: all 0.4s ease;
        }

        &:focus {
          outline: none;
        }

        &__childMenuIcon {
          position: relative;
          top: 0;
          flex: 0 0 18px;
          text-decoration: none;
          font-size: 14px;
          width: 18px;
          height: 18px;
          background-color: var(--base-color);
          mask-image: url('/assets/images/icons/chevron-down.svg');
          -webkit-mask-image: url('/assets/images/icons/chevron-down.svg');
          mask-repeat: no-repeat;
          -webkit-mask-repeat: no-repeat;
          mask-size: cover;
          -webkit-mask-size: cover;
          margin-left: 3px;
          margin-right: auto;

          &.__modalStyle__ {
            background-color: var(--base-color-modal);
          }

          &.open {
            transform: rotate(180deg);
          }
        }
      }

      &__otherChildList {
        display: block;
        z-index: 9999;
        background-color: var(--base-color);
        min-width: 100px;
        margin-top: 10px;
        padding: 10px 15px;
        border-radius: 4px;

        &.__modalStyle__ {
          background-color: var(--base-color-modal);
        }
      }

      &__otherChildItem {
        margin-bottom: 40px;
        text-align: left;
        letter-spacing: 0.06em;

        @media #{ $pcview } {
          margin-bottom: 0;
        }

        &:not(:first-child) {
          @media #{ $pcview } {
            padding-top: 7px;
          }
        }

        &:not(:last-child) {
          @media #{ $pcview } {
            padding-bottom: 7px;
            border-bottom: 1px solid var(--accent-color);
            &.__modalStyle__ {
              border-bottom: 1px solid var(--accent-color-modal);
            }
          }
        }
      }

      &__otherChildLink {
        color: var(--functional-color);
        text-decoration: none;
        font-size: 14px;
        transition: all 0.4s ease;
        overflow-wrap: anywhere;
        word-break: keep-all;
        cursor: pointer;

        &.__modalStyle__ {
          color: var(--functional-color-modal);
        }

        &:hover {
          opacity: 0.4;
          transition: all 0.4s ease;
        }
      }

      &__hamburgerMenu {
        &__childList {
          margin-top: 15px;
          display: block;

          @media #{ $pcview } {
            display: none !important;
          }
        }

        &__childItem {
          text-align: center;
          letter-spacing: 0.06em;
          margin-bottom: 10px;
        }

        &__childLink {
          color: var(--functional-color);
          text-decoration: none;
          font-size: 14px;
          transition: all 0.4s ease;
          &.__modalStyle__ {
            color: var(--functional-color-modal);
          }
          &:hover {
            opacity: 0.4;
            transition: all 0.4s ease;
          }
        }
      }
    }

    &.myPage {
      @media #{ $pcview } {
        display: none;
      }

      &.__modalStyle__ {
        top: 44px;
        height: calc(100% - 44px);
      }
    }

    &_myPage {
      &__list {
        display: none;

        &.active {
          display: block;
        }
      }
    }

    &_appConf {
      display: none;
      overflow: auto;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      padding-top: constant(safe-area-inset-top);
      padding-top: env(safe-area-inset-top);
      background: white;

      &.active {
        display: block;
        animation: none;
        -webkit-animation: none;
      }

      &_header {
        height: 80px;
        text-align: center;
        padding-top: 40px;

        &_close {
          text-align: right;
          padding-right: 15px;

          &_btn {
            height: 24px;
            width: 24px;
          }
        }

        &_back_btn {
          position: absolute;
          left: 30px;
          font-size: 20px;
          color: black;
          transform: scale(1.5);
        }

        &_text {
          font-size: 20px;
        }
      }

      &_accountCoop {
        &_title {
          height: 41px;
          background: #ddd;
          display: flex;
          align-items: center;
          padding-left: 15px;
          border-top: 1px solid #ccc;

          &_text {
            font-size: 15px !important;
            font-weight: bold !important;
          }
        }

        &_body {
          padding: 15px;
          border-bottom: 1px solid #ccc;
          display: table;
          width: 100%;

          &_text {
            font-size: 12px;
            width: 90%;
            display: table-cell;
            vertical-align: middle;
            padding-right: 18px;
          }

          &_next {
            display: table-cell;
            height: 100%;
            text-align: center;
            vertical-align: middle;

            &_btn {
              fill: #777777;
              height: 30px;
            }
          }
        }
      }
    }

    &_accountCoop {
      display: flex;
      overflow: auto;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
      width: 100%;
      max-width: 100%;
      height: 100%;
      padding-top: constant(safe-area-inset-top);
      padding-top: env(safe-area-inset-top);
      transition: all 0.3s;
      align-items: center;

      &_filter {
        display: block;
        background: rgba(150, 150, 150, 0.8);
        height: 100%;
        width: 100%;
        overflow: auto;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        height: 100%;
        padding-top: constant(safe-area-inset-top);
        padding-top: env(safe-area-inset-top);
      }

      &_container {
        margin: 15px;
        min-height: auto;
        background-color: white;
        padding: 15px;
        width: calc(100% - 30px);
        border-radius: 5px;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
      }

      &_close {
        text-align: right;

        &_btn {
          height: 24px;
          width: 24px;
        }
      }

      &_title {
        text-align: center;
        padding-bottom: 20px;

        &_text {
          font-size: 20px;
        }
      }

      &_body {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        &.last {
          margin-bottom: 0px;
        }

        &_site {
          float: left;
          max-width: 60%;

          &_name {
            font-size: 15px;
            font-weight: bold !important;
          }

          &_id {
            display: block;
            font-size: 12px;
            padding-top: 4px;
          }
        }

        &_coopbtn {
          position: relative;
          text-decoration: none;
          border: 2px solid black;
          padding: 2px 22px;
          border-radius: 20px;
          font-size: 12px;
          right: 0;
          bottom: 0;
          line-height: 2em;
          float: right;
          background-color: #000;
          color: #fff;
          height: 35px;

          &:active {
            background-color: #fff;
            color: #000;
          }

          &.release {
            background-color: #fff;
            color: #000;

            &:active {
              background-color: #000;
              color: #fff;
            }
          }
        }
      }
    }

    &_aboutApp {
      display: none;
      overflow: auto;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      padding-top: constant(safe-area-inset-top);
      padding-top: env(safe-area-inset-top);
      background: white;
      line-height: 1.5em;
      font-size: 14px;

      &.active {
        display: block;
        animation: none;
        -webkit-animation: none;
      }

      &_header {
        height: 80px;
        text-align: center;
        padding-top: 30px;

        &_close {
          text-align: right;
          padding-right: 15px;

          &_btn {
            height: 24px;
            width: 24px;
          }
        }

        &_back_btn {
          position: absolute;
          left: 30px;
          font-size: 20px;
          color: black;
          transform: scale(1.5);
        }

        &_text {
          font-size: 20px;
          font-family: #{$default_font_family};
        }
      }

      &_body {
        padding-left: 15px;
        padding-right: 15px;

        &_container {
          &.section-block {
            padding: 15px 0;
          }

          &.title {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;

            &.subtitle {
              font-size: 15px;
            }
          }

          &.text {
            font-size: 14px;
            padding-left: 15px;
            margin-bottom: 10px;

            &_link {
              text-decoration: underline;
            }

            &.marginB20px {
              margin-bottom: 20px;
            }
          }
          .default {
            color: var(--ion-color-primary, #3880ff);
          }
        }

        &_space10px {
          margin-top: 10px;
        }
      }
    }

    &_mailNotification {
      display: none;
      overflow: auto;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      padding-top: constant(safe-area-inset-top);
      padding-top: env(safe-area-inset-top);
      background: white;

      &_button {}
    }
  }

  /*中身*/
  &_shopDetailModalContent {
    overflow: auto;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    margin: 0 10px 0 auto;
    z-index: 9999;
    /* 最前面に */
    width: 50%;
    /* 右側に隙間を作る場合は100%未満 */
    max-width: 50%;
    /* 最大幅を決める際は絶対値指定など */
    height: auto;
    max-height: 400px;
    background-color: var(--base-color-modal);
    /*背景色*/
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    transition: all 0.3s;
    border: 1px solid black;
  }
}

.header_content__closeBtn {
  @media #{ $pcview } {
    display: none;
  }

  &.__modalStyle__ {
    span {

      &::before,
      &::after {
        background: var(--functional-color-modal);
      }
    }
  }

  display: inline-block;
  position: absolute;
  top: calc(24px + constant(safe-area-inset-top));
  top: calc(24px + env(safe-area-inset-top));
  // right: 2.5%;
  right: 13px;
  width: 30px;
  height: 22px;
  vertical-align: middle;
  cursor: pointer;

  span {
    position: absolute;
    height: 2px;
    /*線の太さ*/
    width: 20px;
    /*長さ*/
    background: transparent;
    display: block;
    content: '';

    &::before,
    &::after {
      position: absolute;
      height: 2px;
      /*線の太さ*/
      width: 20px;
      /*長さ*/
      border-radius: 3px;
      background: var(--functional-color);
      display: block;
      content: '';
    }

    &::before {
      bottom: -15px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    &::after {
      bottom: -15px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    &.black {

      &::before,
      &::after {
        background: black;
      }
    }
  }
}

.isDisplayLogoOpacity {
  opacity: 0;
}

.background_none {
  background: unset !important;
}

// 「アカウント削除」の確認アラートデザイン
.deleteAccountMsg>.alert-wrapper>.alert-head>h2 {
  font-size: 17px;
  font-family: var(--ion-font-family, inherit);
}

.alert-button-inner {
  font-family: inherit !important;
}

.deleteAccountMsg_delete>span {
  color: red;
}

.order-first {
  order: -9999;
}

.order-last {
  order: 9999;
}

.order-1 {
  order: 1;
}

.order-99 {
  order: 99;
}